import * as React from 'react';
import { HomeViewContext, TemplateProps } from '../parser';
import { PathService } from '../services';
import { HomeView } from '../views';

const Index: React.FunctionComponent<TemplateProps<HomeViewContext>> = ({
  pageContext,
  location,
}) => {
  PathService.set(location.pathname);
  return <HomeView page={JSON.parse(pageContext.page)} />;
};

export default Index;
